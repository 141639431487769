import React from 'react';
import styled from 'styled-components';
import { Layout, SEO, H1, ContentSection, ContentRight, MoreHeading } from '.';
import { bodyRenderer } from '../helpers/bodyRenderer';
import * as servicesData from '../data/services';
import { Link } from './styles/link';
import { mq } from './styles/media-queries';
import { SolidDivider } from '.';
import { generateRandomSummaryItems } from '../helpers/summaryItems';
import { generateThumbnailData } from '../helpers/generateThumbnailData';

export const PageTemplate = props => {
  const {
    pageContext: { name, imgUrl, thumbnails },
  } = props;

  const nameArray = name.split('-');
  nameArray.forEach((nm, idx) => {
    if (idx > 0) {
      nameArray[idx] = `${nm.substr(0, 1).toUpperCase()}${nm.substr(1)}`;
    }
  });

  const pageDataKey = nameArray.join('');

  const { heading, body } = servicesData[pageDataKey];

  const headingArray = heading.toLowerCase().split(' ');
  const title = headingArray.map(h => {
    return `${h.charAt(0).toUpperCase()}${h.slice(1)}`;
  });

  const thumbnailData = generateThumbnailData(thumbnails);
  const rightSide = generateRandomSummaryItems(thumbnailData, heading);

  const ContentFooter = styled.div`
    ${mq.large} {
      padding-bottom: 32px;
    }
  `;

  return (
    <Layout>
      <SEO title={`Services | ${title.join(' ')}`} />
      <img src={imgUrl} alt={heading} />
      <H1>{heading}</H1>
      <ContentSection>
        <div>
          {body.map((b, idx) => bodyRenderer(b, idx))}
          <SolidDivider />
          <ContentFooter>
            So what are you waiting for? Take the first step and{' '}
            <Link to="/contact">book a free consultation!</Link>
          </ContentFooter>
        </div>
        <ContentRight>
          <MoreHeading>More</MoreHeading>
          {rightSide.map((b, idx) => bodyRenderer(b, `right-side-${idx}`))}
        </ContentRight>
      </ContentSection>
    </Layout>
  );
};

export default PageTemplate;
